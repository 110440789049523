





























































































import { Group } from "@/types/group";
import { Component, Prop, Vue } from "vue-property-decorator";
import { GroupStatus } from "@/types/helpers";
import axios from "axios";
import groupModule from "@/store/modules/groups";
import auth from "@/store/modules/user";
import { User } from "@/types/user";

@Component
export default class GroupItem extends Vue {
  @Prop() group!: Group;
  @Prop({ default: true }) hasJoin!: boolean;
  status = GroupStatus;
  isLoading = false;

  get isMember(): boolean {
    return groupModule.groups.some((item) => item.id == this.group.id);
  }

  get authUser(): User | undefined {
    return auth.user;
  }

  askJoin(): void {
    this.isLoading = true;
    axios({
      url: process.env.VUE_APP_API_BASE_URL + `groups/${this.group.id}/members`,
      method: "POST",
    })
      .then(() => {
        if (auth.user && auth.user.isTeacher && !this.group.isPrivate) {
          this.$toast.success(this.$t("groups.joined").toString());
          groupModule.loadGroups();
          this.$router.push({
            name: "tasks",
            params: { group_id: this.group.id },
          });
        } else {
          this.$toast.info(this.$t("groups.ask").toString());
          groupModule.loadGroups();
        }
      })
      .catch((err) => {
        this.$toast.error(err.response.data.message);
      })
      .finally(() => {
        this.group.status = this.status.PENDING;
      });
  }
}
